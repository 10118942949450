import './Team.css';
import serena from '../images/serena.png';
import robin from '../images/robin.png';
import React from 'react';

function Team() {
    return (
        <div className="Team">
            <div className="TeamHeader">Team</div>
            <div className="TeamInfoBlock">
                <div className="TeamImageBlock"><img src={robin} className="images"/></div>
                <div className="TeamInfo">
                    <div className="TeamName">Yohann Connell</div>
                    <div className="TeamTitle">Developer</div>
                    <div className="TeamDescription">
                       <p>Results-oriented Mobile Developer with 25 years of experience building high-performance, user-centric applications. Proficient in Java, Flutter, Typescript, Angular, React, and C++. Possessing a strong foundation in mobile architecture. Adept at collaborating with cross-functional teams to deliver innovative mobile solutions. Passionate about leveraging the latest technologies to create exceptional user experiences. Seeking a challenging role to contribute to cutting-edge mobile projects.</p>
                    </div>
                </div>
            </div>
            <div className="TeamInfoBlock">
                <div className="TeamImageBlock"><img src={serena} className="images" /></div>
                <div className="TeamInfo">
                    <div className="TeamName">Serena Connell</div>
                    <div className="TeamTitle">Designer</div>
                    <div className="TeamDescription">
                       <p>Creative and strategic designer with 5 years of experience crafting visually compelling and user-centric designs. Proven ability to develop brand identities and create user interfaces. Expertise in Adobe Creative Suite, Figma, Photoshop, and Sketch. Passionate about human-centered design. Skilled in translating complex ideas into innovative and impactful visual solutions.</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Team;