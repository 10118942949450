import './Layout.css';
import React  from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';

function Layout () {
    const location = useLocation();

    return(
        <div className="Layout">
            <div className="LayoutBar">
                <div className="LayoutLinkContainer">
                    <div className="LayoutLink"><Link to="/" className={ location.pathname !== '/' ? "LLink" : "LLinkSelected" }>Home</Link></div>
                    <div className="LayoutLink"><Link to="/games" className={location.pathname !== '/games'? "LLink" : "LLinkSelected"}>Games</Link></div>
                    <div className="LayoutLink"><Link to="/team" className={location.pathname !== '/team'? "LLink" : "LLinkSelected"}>Team</Link></div>
                    <div className="LayoutLink"><Link to="/contactus" className={location.pathname !== '/contactus'? "LLink" : "LLinkSelected"}>Contact Us</Link></div>
                </div>
            </div>
            <Outlet />
        </div>
    );
}

export default Layout;