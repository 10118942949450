import './ContactUs.css';
import React from 'react';

function ContactUs() {
    return (
        <div className="ContactUs">
            <div className="ContactUsContainer">
                <p>
                    <b>Have questions, comments, or need assistance?</b> Reach out to our dedicated support team. You can contact us by email at <a href="mailto:support@chibersan.com">support@chibersan.com</a>. We strive to respond to all inquiries within 24 hours.
                </p>
            </div>
        </div>);
}

export default ContactUs;