import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Games from './pages/Games';
import Team from './pages/Team';
import ContactUs from './pages/ContactUs';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/games" element={<Games />} />
            <Route path="/team" element={<Team />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
