import './Home.css';
import React from 'react';

function Home() {
  return (
    <div className="Home">
        <div className="HomeHeader">
          ChiberSan LLC
        </div>
        <p className="HomeSummary">
            ChiberSan LLC, A unique blend of seasoned expertise and fresh perspective. We're a father-daughter team dedicated to creating games that resonate with players of all ages.
        </p>
        <div className="HomeInfoSection">
            <div className="HomeInfoSectionTitle">News</div>
            <p>Coming in the Winter of 2025 our first game, <b>Summation</b>!</p>
        </div>
    </div>
  );
}

export default Home;